import i18n from '@/common/config/i18n'
import type { components } from '@/common/models'
import { string } from 'yup'

const stringToRegex = (regexString: string) => {
  const regexPattern = regexString.slice(1, regexString.lastIndexOf('/'))
  const regexFlags = regexString.slice(regexString.lastIndexOf('/') + 1)

  const escapedPattern = regexPattern.replace(/\\\\/g, '\\\\')

  return new RegExp(escapedPattern, regexFlags)
}

export const generateCustomFieldSchema = (
  customField:
    | components['schemas']['GetMissingCustomFieldsResponseItem']
    | components['schemas']['GetAppUserCustomFieldResponseItem']
) => {
  const { t } = i18n
  const {
    isRequired,
    regex,
    translation: { regexDescription }
  } = customField
  if (regex !== undefined && regex?.toString().length > 0) {
    if (isRequired) {
      return string()
        .required(t('requiredField'))
        .matches(stringToRegex(regex), regexDescription)
    }
    return string().matches(stringToRegex(regex), regexDescription)
  }

  if (isRequired) {
    return string().required(t('requiredField'))
  }

  return string()
}
