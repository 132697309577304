// External
import type { CompositeScreenProps } from '@react-navigation/native'
import type { StackScreenProps } from '@react-navigation/stack'
import { Tab } from '@rneui/themed'
import { FlashList } from '@shopify/flash-list'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RefreshControl, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import {
  Button,
  CardListSkeleton,
  Icon,
  Text,
  TopBar
} from '@/common/components'
import { VisitCard } from '@/visit/components'
// Constants
import { colors, shadows } from '@/common/constants'
// Hooks
import useRefetchOnFocus from '@/common/hooks/useRefetchOnFocus'
// Models
import { VisitRequestStatus, type components } from '@/common/models'
import type { HomeDrawerParamList } from '@/home/models'
import type { VisitStackParamList } from '@/visit/models'
// Services
import { getRequestCount, getVisits } from '@/visit/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import useNotificationStore from '@/notifications/stores/useNotificationsStore'
// Utils
import { getResultsFromInfiniteQuery } from '@/common/utils'

type Props = CompositeScreenProps<
  StackScreenProps<VisitStackParamList, 'Visits'>,
  StackScreenProps<HomeDrawerParamList>
>

const Visits = ({ navigation }: Props) => {
  const { user, currentUserType, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentUserType: state.currentUserType,
    currentFacility: state.currentFacility
  }))
  const { unreadNotifications } = useNotificationStore((state) => ({
    unreadNotifications: state.unreadNotifications
  }))
  const [tab, setTab] = useState(0)
  const { top, left, right, bottom } = useSafeAreaInsets()
  const { t } = useTranslation()

  const selectedStatus = Object.values(VisitRequestStatus)[
    tab
  ] as VisitRequestStatus

  const getTabTitle = ({
    status,
    index
  }: {
    status: VisitRequestStatus
    index: number
  }) => {
    if (visitCountData !== undefined && index !== tab) {
      const count = visitCountData.find((item) => item.status === status)?.count

      if (typeof count === 'number' && count > 0) {
        return (
          <Text style={styles.tabTitle}>
            {t(status)}
            <Text style={styles.tabTitle} color={colors.rajah}>
              {` ${count}`}
            </Text>
          </Text>
        )
      }
    }

    return t(status)
  }

  const { data: visitCountData, refetch: refetchCount } = useQuery({
    queryKey: [
      'visitCount',
      user?._id,
      currentUserType?._id,
      currentFacility?._id,
      selectedStatus
    ],
    queryFn: getRequestCount
  })

  const {
    data: visitsData,
    isFetching,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    refetch: refetchVisits
  } = useInfiniteQuery({
    queryKey: [
      'visits',
      user?._id,
      currentUserType?._id,
      currentFacility?._id,
      selectedStatus
    ],
    queryFn: async ({ pageParam }) =>
      await getVisits({
        status: selectedStatus,
        page: pageParam
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.hasNextPage && lastPage.nextPage !== null) {
        return lastPage.nextPage
      }

      return null
    }
  })

  const results = getResultsFromInfiniteQuery(visitsData)

  const renderItem = useCallback(
    ({
      item
    }: {
      item: components['schemas']['VisitRequestPaginatedItem']
    }) => <VisitCard key={item._id} visit={item} />,
    [refetchCount, refetchVisits]
  )

  const onEndReached = useCallback(() => {
    if (hasNextPage) {
      void fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage])

  useRefetchOnFocus([refetchCount, refetchVisits])

  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <TopBar
          style={{
            paddingTop: top,
            paddingLeft: left + 25,
            paddingRight: right + 25
          }}
          leftIcon={{
            name: 'back',
            onPress: () => {
              navigation.goBack()
            }
          }}
          title={t('visits')}
          rightIcons={[
            {
              name: 'notifications',
              onPress: () => {
                navigation.navigate('Notifications')
              },
              badgeText:
                unreadNotifications > 0
                  ? unreadNotifications.toString()
                  : undefined
            }
          ]}
        />

        <Tab
          scrollable
          style={{
            paddingTop: 36,
            paddingLeft: left + 25,
            paddingRight: right + 25,
            paddingBottom: 20
          }}
          value={tab}
          onChange={setTab}
        >
          <Tab.Item
            style={{
              marginStart: 0
            }}
            title={getTabTitle({
              status: VisitRequestStatus.PENDING,
              index: 0
            })}
          />
          <Tab.Item
            style={{
              marginStart: 8
            }}
            title={getTabTitle({
              status: VisitRequestStatus.APPROVED,
              index: 1
            })}
          />
          <Tab.Item
            style={{
              marginStart: 8
            }}
            title={getTabTitle({ status: VisitRequestStatus.DENIED, index: 2 })}
          />
          <Tab.Item
            style={{
              marginStart: 8
            }}
            title={getTabTitle({
              status: VisitRequestStatus.CANCELED,
              index: 3
            })}
          />
          <Tab.Item
            style={{
              marginStart: 8
            }}
            title={getTabTitle({
              status: VisitRequestStatus.EXPIRED,
              index: 4
            })}
          />
        </Tab>
      </View>

      {isFetching ? (
        <CardListSkeleton />
      ) : (
        <>
          {results.length > 0 && (
            <View style={styles.listHeader}>
              <View>
                <Text variant="baseBold">
                  {t(`visitListHeaders.${selectedStatus}`)}
                </Text>

                <Text variant="small">
                  {t('seeTheStatusOfYourVisits', { variable: t('variable') })}
                </Text>
              </View>

              <View style={styles.listHeaderCount}>
                <Text color={colors.darkerSlateGray} variant="baseBold">
                  {
                    visitCountData?.find(
                      (item) => item.status === selectedStatus
                    )?.count
                  }
                </Text>
              </View>
            </View>
          )}

          <View style={{ flex: 1 }}>
            <FlashList
              contentContainerStyle={{
                paddingTop: 25,
                paddingHorizontal: 25,
                paddingBottom: 25 + bottom
              }}
              data={results}
              renderItem={renderItem}
              onEndReached={onEndReached}
              ItemSeparatorComponent={() => <View style={{ height: 25 }} />}
              ListEmptyComponent={() => (
                <View style={styles.listEmpty}>
                  <Text
                    color={colors.placeholder}
                    variant="medium"
                    style={{ marginBottom: 56 }}
                  >
                    {t(`visitListEmpty.${selectedStatus}`)}
                  </Text>

                  <Icon
                    style={{ opacity: 0.2 }}
                    name="request-list-empty"
                    size={96}
                    color={colors.hydro}
                  />
                </View>
              )}
              refreshControl={
                <RefreshControl
                  refreshing={isRefetching}
                  onRefresh={() => {
                    void refetchCount()
                    void refetchVisits()
                  }}
                />
              }
              estimatedItemSize={176}
            />
          </View>
        </>
      )}

      <Button
        style={{
          marginBottom: bottom + 25,
          marginLeft: left + 25,
          marginRight: right + 25
        }}
        title={t('requestVisit')}
        onPress={() => {
          navigation.navigate('RequestAVisit')
        }}
      />
    </View>
  )
}

export default Visits

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F9F9FB'
  },
  top: {
    backgroundColor: colors.white,
    borderBottomStartRadius: 20,
    borderBottomEndRadius: 20,
    ...shadows.default
  },
  tabTitle: {
    fontFamily: 'PlusJakartaSans_700Bold',
    fontSize: 12,
    lineHeight: 13,
    letterSpacing: 0.07
  },
  listHeader: {
    marginTop: 20,
    paddingHorizontal: 25,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  listHeaderCount: {
    backgroundColor: colors.inactive,
    borderColor: '#E5E5EA',
    borderWidth: 1.5,
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center'
  },
  listEmpty: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
